import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import ru from 'antd/lib/locale/ru_RU';
import './styles/main.scss';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { AppRouter } from './AppRouter';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConfigProvider locale={ru}>
                <AppRouter />
            </ConfigProvider>
        </Provider>
    </React.StrictMode>
);