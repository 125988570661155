import { 
    BaseQueryFn, 
    FetchArgs, 
    FetchBaseQueryError, 
    createApi, 
    fetchBaseQuery 
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { localStorageKeys } from '../constants/localStorage';

export const API_URL = process.env.REACT_APP_API_URL;

export enum apiTags {
    users = 'users',
    roles = 'roles',
    clients = 'clients',
    referrals = 'referrals',
    curUser = 'curUser',
    settings = 'settings',
    deposits = 'deposits',
    transactions = 'transactions',
    withdraw = 'withdraw',
    privateMessages = 'privateMessages',
    newsletterMessages = 'newsletterMessages'
};

export enum apiMethods {
    post = 'POST',
    put = 'PUT',
    delete = 'DELETE'
};

export const listId = 'LIST';
export const mutex = new Mutex();

const baseQuery = fetchBaseQuery({ 
    baseUrl: API_URL ?? '', 
    credentials: 'include',
    mode: 'cors',
    prepareHeaders: (headers: Headers) => {
        const token = localStorage.getItem(localStorageKeys.authToken);

        if (token) {
            headers.set('Authorization', `Bearer ${token}`)
        }

        return headers;
    }
});

const baseQueryAuth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, appApi, extraOptions) => {
    await mutex.waitForUnlock();
    let result = await baseQuery(args, appApi, extraOptions);

    if (result.error && result.error.status === 401) {
        if(!mutex.isLocked()) {
            const release = await mutex.acquire();

            try {
                const refreshResult = await baseQuery({
                        url: '/auth/refresh', 
                        method: 'GET'
                }, appApi, extraOptions);

                if (refreshResult.data) {
                    appApi.dispatch({
                        payload: refreshResult.data as UserData,
                        type: 'userState/refresh'
                    });

                    result = await baseQuery(args, appApi, extraOptions);
                } else {
                    appApi.dispatch({
                        payload: undefined,
                        type: 'userState/logout'
                    });
                }
            } finally {
                release();
            }
        } else {
            await mutex.waitForUnlock();
            result = await baseQuery(args, appApi, extraOptions);
        }
    }
    
    return result;
}

export const api = createApi({
    reducerPath: 'api',
    tagTypes: Object.values(apiTags),
    baseQuery: baseQueryAuth,
    endpoints: () => ({})
});

export type RootApiType = typeof api;