export enum UserPermissions { 
    adminSettings = 'adminSettings',
    adminRoles = 'adminRoles',
    adminUsers = 'adminUsers',
    accessClients = 'accessClients',
    manageClients = 'manageClients',
    accessStats = 'accessStats',
    accessTransactions = 'accessTransactions',
    manageTransactions = 'manageTransactions',
    accessDeposits = 'accessDeposits',
    manageDeposits = 'manageDeposits',
    accessNewsletter = 'accessNewsletter',
    manageNewsletter = 'manageNewsletter',
    accessWithdraw = 'accessWithdraw',
    manageWithdraw = 'manageWithdraw',
    super = 'super'
};

export enum transactionTypes {
    p2pIncoming = 'p2pIncoming',
    p2pOutgoing = 'p2pOutgoing',
    referral = 'referral',
    admin = 'admin',
    deposite = 'deposite',
    withdraw = 'withdraw',
    buyDeposite = 'buyDeposite',
    withdrawReturn = 'withdrawReturn'
};

export enum DepositAmount {
    FIRST = 10, 
    SECOND = 20, 
    THIRD = 40, 
    FOURTH = 80, 
    FIFTH = 160, 
    SIXTH = 320, 
    SEVENTH = 640, 
    EIGHTH = 1280, 
    NINE = 2560, 
    TENTH = 5120, 
    ELEVENTH = 10240, 
    TWELVE = 20480
};

export enum DepositeTypes {
    FIRST = 'FIRST', 
    SECOND = 'SECOND', 
    THIRD = 'THIRD', 
    FOURTH = 'FOURTH', 
    FIFTH = 'FIFTH', 
    SIXTH = 'SIXTH', 
    SEVENTH = 'SEVENTH', 
    EIGHTH = 'EIGHTH', 
    NINE = 'NINE', 
    TENTH = 'TENTH', 
    ELEVENTH = 'ELEVENTH', 
    TWELVE = 'TWELVE'
};

export enum WithdrawRequestStatus {
    APPROVED = 'APPROVED',
    NEW = 'NEW',
    CANCELED = 'CANCELED'
};

export enum Languages {
    en = 'Английский',
    ru = 'Русский',
    es = 'Испанский',
    fr = 'Французский',
    hi = 'Хинди',
    de = 'Немецкий',
    cn = 'Китайский',
    pl = 'Польский',
    tr = 'Турецкий'
};