import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { useLazyCheckAuthQuery } from './app/endpoints/authApi';
import { useEffect, useMemo } from 'react';
import { localStorageKeys } from './constants/localStorage';
import useCurrentUser from './hooks/useCurrentUser';
import { Throbber } from './components/Throbber';
import { getRoutesByAuth } from './router';

export const AppRouter = () => {
    const [ checkAuth, { isLoading, isFetching, isUninitialized } ] = useLazyCheckAuthQuery();
    
    const token = useMemo(() => localStorage.getItem(localStorageKeys.authToken), []);

    useEffect(() => {
        if (token) {
            checkAuth(undefined);
        }
    }, [ checkAuth, token ]);

    const user = useCurrentUser();
    const routes = useMemo(() => getRoutesByAuth(user), [ user ]);
    const router = createBrowserRouter(routes);

    return (
        isLoading || isFetching || (token && isUninitialized)
            ?   <Throbber className='vh100'/>
            :   <RouterProvider router={router}/>
    )       
}

