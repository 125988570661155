import { Flex, Typography } from 'antd';

export const NoPermissions = () => {
    return (
        <Flex
            align='center'
            justify='center'
        >
            <Typography.Title level={2}>
                Нет прав
            </Typography.Title>
        </Flex>
    )
}
