import { notification } from 'antd';

type notifiType = 'warning' | 'error' | 'success' | 'info';

export const systemNotify = (type: notifiType, message: string) => notification[type]({
    message,
    placement: 'topRight',
    duration: 3,
});

export const incomingNotify = (type: notifiType, title: string, body: string) => notification[type]({
    message: title,
    description: body,
    placement: 'bottomRight',
    duration: 15
});