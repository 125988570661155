import { Suspense } from 'react';
import { Throbber } from './components/Throbber';
import { Outlet } from 'react-router-dom';

function App() {
    return (
        <Suspense fallback={<Throbber className='vh100'/>}>
            <Outlet/>
        </Suspense>
    );
}

export default App;
