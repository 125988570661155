import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { api } from './api';
import userState from './slices/userSlice';
import { notificationMiddleware } from './notificationsMiddleware';

const rootReducer = combineReducers({
    userState,
    [api.reducerPath]: api.reducer,
});

export const store = configureStore({
    reducer: rootReducer,  
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat([
        notificationMiddleware,
        api.middleware, 
    ])
});

export type AppRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;