import { api, apiMethods, apiTags } from '../api';

const url = 'auth';

export const authApi = api.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation<UserData, LoginBody>({
            query: data => ({
                url: `${url}/login`,
                method: apiMethods.post,
                body: data
            })
        }),
        checkAuth: builder.query<UserData, undefined>({
            query: () => ({
                url: `${url}/refresh`,
            }),
            keepUnusedDataFor: 0,
            providesTags: [ apiTags.curUser ]
        }),
        logout: builder.mutation<null, undefined>({
            query: () => ({
                url: `${url}/logout`,
                method: apiMethods.post
            })
        }),
        restore: builder.mutation<messageResponse, RestorePasswordBody>({
            query: data => ({
                url: `${url}/restore`,
                method: apiMethods.post,
                body: data
            })
        }),
        activation: builder.query<ActivationResponse, string>({
            query: key => ({
                url: `${url}/activation?key=${key}`
            })
        }),
        setPassword: builder.mutation<messageResponse & UserData, SetPasswordBody>({
            query: body => ({
                url: `${url}/activation`,
                method: apiMethods.post,
                body
            })
        })
    })
});

export const { 
    useLoginMutation, 
    useLogoutMutation, 
    useCheckAuthQuery, 
    useRestoreMutation, 
    useActivationQuery, 
    useSetPasswordMutation, 
    useLazyCheckAuthQuery
} = authApi;