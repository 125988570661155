import { Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { RowProps } from 'antd/lib';

interface LoaderProps extends RowProps {
    size?: string;
}

export const Throbber = ({
    size,
    ...props
}: LoaderProps) => {
    return (
        <Row 
            align='middle' 
            justify='center'
            {...props}
        >
            <Spin indicator={<LoadingOutlined style={{ 
                fontSize: size ?? '64px'
            }}/>}/>
        </Row>
    )
};
